import { Heading } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import type { NavItem } from '@hubcms/domain-navigation';

import { DropdownMenu } from '../DropdownMenu';
import { NavigationItem } from '../NavigationItem';

import styles from './subnav.module.scss';

export type SubnavProps = {
  className?: string;
  title?: string;
  items: NavItem[];
  testId?: string;
  trackingBlock?: string;
  isScrolling?: boolean;
};

export function Subnav({
  className,
  title,
  items,
  testId,
  isScrolling = false,
  trackingBlock = 'sub-navigation-tree',
}: SubnavProps) {
  return (
    <div className={cx(styles.subnav, className)} data-testid={testId}>
      {title && (
        <Heading className={styles.title} level={1}>
          {title}
        </Heading>
      )}
      {items.map(item => {
        if (item.subnavItems && item.subnavItems.length !== 0 && !item.url) {
          return (
            <DropdownMenu
              isScrolling={isScrolling}
              key={item.uniqueId}
              className={styles.link}
              text={item.text}
              navigationItems={item.subnavItems}
              trackingBlock={trackingBlock}
            />
          );
        }

        return <NavigationItem key={item.uniqueId} navItem={item} className={styles.link} />;
      })}
    </div>
  );
}
