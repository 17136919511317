import { authMethod } from '@hubcms/brand';
import { useAuth } from '@hubcms/data-access-auth';
import { useEnv } from '@hubcms/data-access-env';
import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import type { NavItem } from '@hubcms/domain-navigation';
import { getLogoutRedirectUrl } from '@hubcms/utils-auth';
import { createMenuEvent } from '@hubcms/utils-tracking';

import { NavigationUserMenuAuthenticated } from './NavigationUserMenuAuthenticated';
import { NavigationUserMenuUnauthenticated } from './NavigationUserMenuUnauthenticated';

type NavigationUserMenuProps = {
  items: NavItem[];
  loginUrl: string;
  size?: 'sm' | 'lg';
};

export function NavigationUserMenu({ items, size = 'lg', loginUrl }: NavigationUserMenuProps) {
  const { isAuthenticated, user, logout, login } = useAuth();
  const env = useEnv();

  if (authMethod === 'none') {
    return null;
  }

  const handleLogin = async () => {
    await sendTrackingEvent(
      createMenuEvent({
        eventlabel: 'login',
      }),
    );
    login(loginUrl);
  };

  const handleLogout = async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'logout' }));
    logout(getLogoutRedirectUrl(authMethod, env));
  };

  if (isAuthenticated && user) {
    return <NavigationUserMenuAuthenticated items={items} size={size} user={user} onLogout={handleLogout} />;
  }

  return <NavigationUserMenuUnauthenticated size={size} onLogin={handleLogin} />;
}
