import type { NavItem } from '@hubcms/domain-navigation';

import { HeaderDesktopServices } from './HeaderDesktopServices';
import { HeaderDesktopTopics } from './HeaderDesktopTopics';
import styles from './header.module.scss';

type HeaderDesktopProps = {
  loginUrl: string;
  serviceItems: NavItem[];
  subscriptionItem?: NavItem;
  topicItems: NavItem[];
  userMenuItems: NavItem[];
};

export function HeaderDesktop({ loginUrl, serviceItems, subscriptionItem, topicItems, userMenuItems }: HeaderDesktopProps) {
  return (
    <div className={styles.headerDesktop} data-testid="header-desktop">
      <HeaderDesktopServices
        loginUrl={loginUrl}
        navigationItems={serviceItems}
        subscriptionItem={subscriptionItem}
        userMenuItems={userMenuItems}
      />
      <HeaderDesktopTopics navigationItems={topicItems} />
    </div>
  );
}
