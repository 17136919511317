import { Button, IconButton } from '@mediahuis/chameleon-react';
import { Close, MenuSearch } from '@mediahuis/chameleon-theme-wl/icons';

import { useNavigation } from '@hubcms/data-access-navigation';

import styles from './navigation-menu.module.scss';

type CommonNavigationMenuProps = {
  closeLabel: string;
  label: string;
};

type IconButtonNavigationMenuProps = CommonNavigationMenuProps & {
  component: 'IconButton';
  buttonSize?: never;
};

type ButtonNavigationMenuProps = CommonNavigationMenuProps & {
  component: 'Button';
  buttonSize: 'sm' | 'lg';
};

type NavigationMenuProps = IconButtonNavigationMenuProps | ButtonNavigationMenuProps;

export function NavigationMenu({ closeLabel, label, buttonSize, component }: NavigationMenuProps) {
  const { isAsideMenuOpen, toggleIsAsideMenuOpen } = useNavigation();

  if (component === 'IconButton') {
    return (
      <IconButton
        aria-label={label}
        className={styles.navigationMenuButton}
        data-testid="navigation-menu"
        icon={isAsideMenuOpen ? Close : MenuSearch}
        size="lg"
        onClick={toggleIsAsideMenuOpen}
      />
    );
  }

  return (
    <Button
      className={styles.navigationMenuButton}
      data-testid="navigation-menu"
      iconLeft={isAsideMenuOpen ? Close : MenuSearch}
      size={buttonSize}
      onClick={toggleIsAsideMenuOpen}
    >
      {isAsideMenuOpen ? closeLabel : label}
    </Button>
  );
}
