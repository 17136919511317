import { Dropdown, Icon } from '@mediahuis/chameleon-react';
import { ChevronDown } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import { useEffect, useRef } from 'react';

import type { NavItem } from '@hubcms/domain-navigation';
import { MenuItem } from '@hubcms/ui-navigation-2';

import { NavigationItem } from '../NavigationItem';

import styles from './dropdown-menu.module.scss';

type DropdownMenuProps = {
  className?: string;
  text: string;
  navigationItems: NavItem[];
  trackingBlock: string;
  isScrolling?: boolean;
};

export function DropdownMenu({ className, text, navigationItems, isScrolling = false, trackingBlock }: DropdownMenuProps) {
  const ref = useRef<typeof Dropdown>(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (isScrolling) {
      ref.current.close();
    }
  }, [isScrolling]);

  return (
    <>
      <button ref={anchorRef} className={cx(styles.dropdownButton, className)} onClick={() => ref.current.toggle()}>
        {text} <Icon as={ChevronDown} size="sm" />
      </button>
      <Dropdown ref={ref} anchorRef={anchorRef} className={styles.dropdown}>
        <div className={styles.dropdownContent}>
          {navigationItems.map(item => (
            <NavigationItem key={item.uniqueId} navItem={item}>
              {({ href }) => <MenuItem href={href} text={item.text} trackingBlock={trackingBlock} />}
            </NavigationItem>
          ))}
        </div>
      </Dropdown>
    </>
  );
}
