import type { NavItem } from '@hubcms/domain-navigation';

import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';
import styles from './header.module.scss';

export type HeaderProps = {
  loginUrl: string;
  serviceItems: NavItem[];
  subscriptionItem?: NavItem;
  topicItemsMobile: NavItem[];
  topicItemsDesktop: NavItem[];
  userMenuItems: NavItem[];
};

export function Header({
  loginUrl,
  serviceItems,
  subscriptionItem,
  topicItemsDesktop,
  topicItemsMobile,
  userMenuItems,
}: HeaderProps) {
  return (
    <header className={styles.header}>
      <HeaderDesktop
        loginUrl={loginUrl}
        serviceItems={serviceItems}
        subscriptionItem={subscriptionItem}
        topicItems={topicItemsDesktop}
        userMenuItems={userMenuItems}
      />
      <HeaderMobile
        loginUrl={loginUrl}
        subscriptionItem={subscriptionItem}
        topicItems={topicItemsMobile}
        userMenuItems={userMenuItems}
      />
    </header>
  );
}
