import { Logo } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import { NavigationLink } from '../NavigationLink';

import styles from './navigation-logo.module.scss';

type NavigationLogoSize = 'sm' | 'md' | 'lg';

const logoConfig: Record<NavigationLogoSize, { height: string }> = {
  sm: {
    height: 'var(--scale-9)',
  },
  md: {
    height: 'var(--scale-10)',
  },
  lg: {
    height: 'var(--scale-11)',
  },
};

type NavigationLogoProps = {
  logoName: string;
  size?: NavigationLogoSize;
  className?: string;
};

export function NavigationLogo({ logoName, size = 'md', className }: NavigationLogoProps) {
  const config = logoConfig[size];

  return (
    <NavigationLink
      data-testid="navigation-logo"
      trackingName="home"
      trackingBlock="header-logo"
      href="/"
      style={{ height: config.height }}
    >
      <Logo className={cx(styles.navigationLogo, className)} name={logoName} />
    </NavigationLink>
  );
}
