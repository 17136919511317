import { domain } from '@hubcms/brand';
import { createTrackingClickHandler } from '@hubcms/data-access-tracking';
import type { TrackingEvent } from '@hubcms/domain-tracking';
import { getIsSiteDomain, isAboshopLink, isCiamLink } from '@hubcms/utils-navigation';
import {
  createClickCiamEvent,
  createClickSubscriptionShopEvent,
  createNavigationOrOutboundClickEvent,
  isClickData,
  mapClickCiamData,
  mapClickData,
  mapClickSubscriptionShopData,
} from '@hubcms/utils-tracking';
import { createUrl } from '@hubcms/utils-url';

export function createNavigationItemClickHandler({
  trackingBlock,
  trackingName,
  href,
}: {
  trackingBlock?: string;
  trackingName?: string;
  href?: string;
}) {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const clickTrackingData = mapClickData({
    clickitemblock: trackingBlock,
    clickitemname: trackingName,
    clickitemurl: href,
  });

  if (!clickTrackingData) {
    return undefined;
  }

  const url = href ? createUrl(href) : null;
  const isSiteDomain = url ? getIsSiteDomain(url, domain) : false;
  const trackingEvents: TrackingEvent[] = [
    createNavigationOrOutboundClickEvent(clickTrackingData, {
      isSiteDomain,
    }),
  ];

  const isAboshopDomain = url ? isAboshopLink(url) : false;

  if (isSiteDomain && isAboshopDomain) {
    const clickSubscriptionShopData = mapClickSubscriptionShopData(clickTrackingData);

    if (clickSubscriptionShopData) {
      trackingEvents.push(createClickSubscriptionShopEvent(clickSubscriptionShopData));
    }
  }

  const isCiamDomain = url ? isCiamLink(url) : false;
  if (isSiteDomain && isCiamDomain && isClickData(clickTrackingData)) {
    const clickCiamData = mapClickCiamData(clickTrackingData, href);
    trackingEvents.push(createClickCiamEvent(clickCiamData));
  }

  return createTrackingClickHandler(trackingEvents);
}
