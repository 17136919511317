import { Icon } from '@mediahuis/chameleon-react';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import type { IconName } from './iconNames';

function importChameleonIcon(iconName: string): Promise<ReactElement | undefined> {
  return import('@mediahuis/chameleon-theme-wl/icons').then(icons => icons[iconName]);
}

export type DynamicIconProps = {
  name: IconName | string;
};

export function DynamicIcon({ name }: DynamicIconProps) {
  const [iconElement, setIconElement] = useState<ReactElement | undefined>(undefined);

  useEffect(() => {
    importChameleonIcon(name)
      .then(chameleonIcon => setIconElement(chameleonIcon))
      .catch(() => setIconElement(undefined));
  }, [name]);

  return iconElement ? <Icon as={iconElement} /> : null;
}
