import { type ReactNode } from 'react';

import { domain } from '@hubcms/brand';
import type { NavItem } from '@hubcms/domain-navigation';
import type { NavigationLinkProps } from '@hubcms/ui-navigation-2';
import { NavigationLink } from '@hubcms/ui-navigation-2';

import { useNavigationHref } from '../../hooks/useNavigationHref';

type NavigationItemChildrenProps = {
  href: string;
  isExternal: boolean;
};

type NavigationItemProps = {
  children?: (props: NavigationItemChildrenProps) => ReactNode;
  navItem: NavItem;
} & Omit<NavigationLinkProps, 'href' | 'children'>;

export function NavigationItem({ children, navItem, ...linkProps }: NavigationItemProps) {
  const href = useNavigationHref(navItem, linkProps.trackingBlock);

  if (children) {
    return children({ isExternal: !href.includes(domain), href });
  }

  return (
    <NavigationLink {...linkProps} href={href} trackingName={navItem.text}>
      {navItem.text}
    </NavigationLink>
  );
}
