import { domain } from '@hubcms/brand';
import type { TrackingData } from '@hubcms/domain-tracking';
import { createCiamLinkUtmParams } from '@hubcms/utils-tracking';
import { addSearchParams, createUrl } from '@hubcms/utils-url';

import { getIsSiteDomain } from '../getIsSiteDomain';
import { isCiamLink } from '../isCiamLink';

export function useCiamUrl(trackingData: TrackingData, href?: string, menuBlockName?: string): string | undefined {
  if (!href) {
    return href;
  }

  const url = href ? createUrl(href) : null;

  if (url && getIsSiteDomain(url, domain) && isCiamLink(url)) {
    url.search = addSearchParams(
      url.search,
      createCiamLinkUtmParams({
        path: url.pathname,
        articleId: trackingData.pageid,
        menuBlockName,
      }),
    );
  }

  return url ? url.toString() : href;
}
