import { useCallback, useSyncExternalStore } from 'react';

import { domain } from '@hubcms/brand';
import { useTrackingData } from '@hubcms/data-access-tracking';
import type { NavItem } from '@hubcms/domain-navigation';
import { getIsSiteDomain, isAboshopLink, useExternalConsentUrl } from '@hubcms/utils-navigation';
import { getSubscriptionShopUtmUrl } from '@hubcms/utils-tracking';
import { createUrl } from '@hubcms/utils-url';

function subscribe() {
  return () => {};
}

function getResolvedUrl(navItem: NavItem, isClient: boolean) {
  const serverUrl = navItem.serverUrl || navItem.url || '/';

  if (isClient) {
    return navItem.clientUrl || serverUrl;
  }

  return serverUrl;
}

export function useNavigationHref(navItem: NavItem, trackingBlock?: string): string {
  const getSnapshot = useCallback(() => getResolvedUrl(navItem, true), [navItem]);
  const getServerSnapshot = useCallback(() => getResolvedUrl(navItem, false), [navItem]);
  const resolvedUrl = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  let href = '';

  const url = createUrl(resolvedUrl);
  const externalUrl = useExternalConsentUrl(resolvedUrl);

  const trackingData = useTrackingData();

  const isAboshopDomain = isAboshopLink(url);
  const isSiteDomain = getIsSiteDomain(url, domain);
  const isAboshopAndSiteDomain = isAboshopDomain && isSiteDomain;

  if (isAboshopAndSiteDomain && !externalUrl) {
    href = getSubscriptionShopUtmUrl(url, trackingData.pageid, trackingBlock);
  } else {
    href = externalUrl || url.toString();
  }

  return href;
}
